<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8">
        <h1>Nuevo Ticket</h1>
        <v-form v-model="isFormValid" ref="form" class="my-4">
          <v-text-field
            v-model="ticketData.subject"
            label="Asunto del Ticket"
            dense
            :rules="[rules.required]"
            required
            outlined
          ></v-text-field>
          <v-select
            v-model="ticketData.them"
            :items="filteredTema"
            label="Tema de ayuda"
            dense
            outlined
            :rules="[rules.required]"
            required
          ></v-select>

          <div>
            <v-textarea
              v-model="ticketData.description"
              :label="
                ticketData.them == 'Alta de Embarcaciones' ||
                ticketData.them == 'Alta de Agencias'
                  ? 'Motivo del alta'
                  : 'Descripción del problema'
              "
              :rules="[rules.required]"
              required
              outlined
            ></v-textarea>

            <v-select
              v-model="ticketData.priority"
              :items="['Baja', 'Media', 'Alta']"
              label="Prioridad"
              dense
              :rules="[rules.required]"
              outlined
              required
            ></v-select>
          </div>
          <div v-if="ticketData.them == 'Alta de Embarcaciones'">
            <v-row dense>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  dense
                  outlined
                  required
                  :rules="[rules.required]"
                  v-model="embarcacionData.nombre"
                  label="Nombre de buque"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  dense
                  outlined
                  :rules="[rules.required]"
                  required
                  v-model="embarcacionData.armador"
                  label="Armador"
                ></v-text-field>
              </v-col>

              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  dense
                  :rules="[rules.required]"
                  outlined
                  required
                  v-model="embarcacionData.bandera"
                  label="Bandera"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  dense
                  outlined
                  :rules="[rules.required]"
                  required
                  v-model="embarcacionData.pto_matricula"
                  label="Pto Matricula"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  dense
                  outlined
                  :rules="[rules.required]"
                  required
                  v-model="embarcacionData.eslora"
                  type="number"
                  label="Eslora"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  dense
                  outlined
                  required
                  :rules="[rules.required]"
                  v-model="embarcacionData.puntal"
                  type="number"
                  label="Puntal"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  dense
                  :rules="[rules.required]"
                  outlined
                  required
                  type="number"
                  v-model="embarcacionData.manga"
                  label="Manga"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  dense
                  :rules="[rules.required]"
                  outlined
                  required
                  v-model="embarcacionData.trn"
                  type="number"
                  label="TRN"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  dense
                  outlined
                  :rules="[rules.required]"
                  required
                  v-model="embarcacionData.tbr"
                  type="number"
                  label="TRB"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  dense
                  :rules="[rules.required]"
                  outlined
                  required
                  v-model="embarcacionData.matricula"
                  label="No. Matricula"
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  dense
                  outlined
                  required
                  :rules="[rules.required]"
                  v-model="embarcacionData.calado"
                  label="Calado"
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  dense
                  outlined
                  :rules="[rules.required]"
                  required
                  v-model="embarcacionData.imo"
                  label="IMO"
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-text-field
                  dense
                  outlined
                  :rules="[rules.required]"
                  required
                  v-model="embarcacionData.tipo"
                  label="Clase"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <div v-if="ticketData.them == 'Alta de Agencias'">
            <v-row dense>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  dense
                  outlined
                  required
                  :rules="[rules.required]"
                  v-model="agenciasData.nombre"
                  label="Nombre o razón social"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  dense
                  outlined
                  required
                  :rules="[rules.required]"
                  v-model="agenciasData.direccion"
                  label="Dirección"
                ></v-text-field>
              </v-col>

              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  dense
                  outlined
                  required
                  :rules="[rules.required]"
                  v-model="agenciasData.rfc"
                  label="RFC"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  dense
                  outlined
                  :rules="[rules.required]"
                  required
                  v-model="agenciasData.email"
                  label="E-mail"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  dense
                  outlined
                  required
                  :rules="[rules.required]"
                  v-model="agenciasData.representante"
                  label="Representante"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <v-file-input
            v-model="file"
            :rules="fileRules"
            dense
            @change="validateFileSize"
            show-size
            :label="
              ticketData.them == 'Alta de Embarcaciones'
                ? 'Certificado de Arqueo'
                : 'Evidencia'
            "
            accept="image/*,.pdf"
            outlined
          ></v-file-input>

          <v-btn
            :disabled="!isFormValid"
            color="primary"
            :loading="loading"
            @click="submitTicket"
            >Enviar Ticket</v-btn
          >
        </v-form>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>Resumen de Tickets</v-card-title>
          <v-divider></v-divider>
          <v-list nav dense>
            <v-list-item-group v-model="selectedItem" color="blue">
              <v-list-item @click="irSeguimiento('abiertos')">
                <v-list-item-avatar>
                  <v-icon color="red">pending</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="ml-3">Abiertos</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small color="gray" dark> {{ tickets["abiertos"] }} </v-chip>
                </v-list-item-action>
              </v-list-item>
              <v-list-item @click="irSeguimiento('en proceso')">
                <v-list-item-avatar>
                  <v-icon color="orange">timer</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="ml-3">En proceso</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small color="gray" dark> {{ tickets["en_proceso"] }} </v-chip>
                </v-list-item-action>
              </v-list-item>
              <v-list-item @click="irSeguimiento('resueltos')">
                <v-list-item-avatar>
                  <v-icon color="green">check</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="ml-3">Resueltos</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small color="gray" dark> {{ tickets["resueltos"] }} </v-chip>
                </v-list-item-action>
              </v-list-item>
              <v-list-item @click="irSeguimiento('cerrados')">
                <v-list-item-avatar>
                  <v-icon color="black">task_alt</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="ml-3">Cerrados</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small color="gray" dark> {{ tickets["cerrados"] }} </v-chip>
                </v-list-item-action>
              </v-list-item>
              <v-list-item @click="irSeguimiento('cancelados')">
                <v-list-item-avatar>
                  <v-icon color="black">delete</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="ml-3">Cancelados</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small color="black" dark> {{ tickets["cancelados"] }} </v-chip>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { crearTicket, getTickets } from "../../api/endpoints";
import { uploadDocument } from "../../helpers/handleUploadsDocument";

export default {
  data() {
    return {
      isFormValid: false,
      ticketData: {
        subject: "",
        them: "",
        description: "",
        priority: "",
      },
      embarcacionData: {
        nombre: "",
        armador: "",
        bandera: "",
        pto_matricula: null,
        eslora: null,
        puntal: null,
        manga: null,
        trn: null,
        tbr: null,
        matricula: "",
        calado: "",
        imo: "",
        tipo: "",
      },
      agenciasData: {
        nombre: "",
        direccion: "",
        rfc: "",
        email: "",
        representante: "",
      },
      isAdmin: false,
      tema: [
        "Problemas de Acceso a la Cuenta",
        "Alta de Embarcaciones",
        "Alta de Agencias",
        "Recuperación de Contraseña",
        "Errores en la Aplicación",
        "Soporte Técnico General",
        "Consultas sobre Actualizaciones",
        "Funciones No Disponibles",
        "Configuración de la Cuenta",
        "Cambios en Datos Personales",
        "Problemas con Notificaciones",
        "Cancelación de Servicios",
        "Sugerencias y Mejoras",
        "Otro",
      ],
      loading: false,
      selectedItem: "",
      tickets: [],
      file: null,
      fileRules: [
        (file) => this.isFileSizeValid(file) || "El archivo debe ser menor de 2 MB",
      ],
      rules: {
        required: (value) => !!value || "Este campo es requerido",
      },
    };
  },
  computed: {
    filteredTema() {
      if (this.$store.state.usuario.rol !== "Agencia") {
        return this.tema;
      }
      return this.tema.filter((item) => item !== "Alta de Agencias");
    },
  },
  created() {
    this.getTicketsResume();
    console.log(this.$store.state.usuario.rol);
  },
  methods: {
    async submitTicket() {
      // Aquí puedes manejar la lógica de envío del ticket
      this.loading = true;

      if (this.$refs.form.validate()) {
        let evidencia = "";
        if (this.file) {
          evidencia = await uploadDocument(this.file);
        }
        const datos = {
          asunto: this.ticketData.subject,
          tema: this.ticketData.them,
          descripcion: this.ticketData.description,
          prioridad: this.ticketData.priority,
          evidencia: evidencia,
        };
        if (this.ticketData.them == "Alta de Embarcaciones") {
          datos.dataEmbarcacion = JSON.stringify(this.embarcacionData);
        }
        if (this.ticketData.them == "Alta de Agencias") {
          datos.dataAgencias = JSON.stringify(this.agenciasData);
        }
        crearTicket(datos)
          .then(() => {
            this.$notify({
              title: "¡Éxito!",
              text: "La operación se completo con éxito",
              type: "success",
            });
            this.getTicketsResume();
            this.resetForm();
          })
          .catch((err) => {
            this.$notify({
              title: "¡Error!",
              text: "La operación no se completo, revise con el administrador",
              type: "success",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async getTicketsResume() {
      this.loading = true;
      await getTickets()
        .then((e) => {
          this.tickets = e;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    irSeguimiento(estatus) {
      this.$router.push({ name: "soporte_estatus", params: { estatus } });
    },
    resetForm() {
      // Limpiar los datos del formulario
      this.ticketData = {
        subject: "",
        them: "",
        description: "",
        priority: "",
      };

      // Resetear la validación del formulario
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    isFileSizeValid(file) {
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
      return file && file.size <= maxSizeInBytes;
    },
    validateFileSize(file) {
      if (!this.isFileSizeValid(file)) {
        this.file = null;
        this.$emit("input", null); // Limpia el archivo en caso de exceder el límite
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  margin-top: 20px;
}
</style>
