<template>
  <div>
    <div class="pb-10 d-flex align-center justify-space-between">
      <div>
        <h2>Bitacora de agenciamiento</h2>
        <span style="font-size: 13px; color: gray">
          Registra los accesos y usos de infraestructura de las embarcaciones
        </span>
      </div>

      <v-btn color="black" @click="dialogBitacora = true" dark small
        >Nuevo registro</v-btn
      >
    </div>
    <v-dialog v-model="dialogBitacora" max-width="1500px">
      <v-card>
        <v-toolbar color="primary" dark>Registrar acceso</v-toolbar>

        <v-container>
          <v-row>
            <v-col cols="5">
              <span style="font-size: 13px; color: gray; margin-bottom: 10px"
                >Ingresa los datos requeridos</span
              >
              <v-container>
                <v-row dense>
                  <v-col class="" cols="12" sm="12">
                    <v-select
                      :items="solicitudes"
                      dense
                      :rules="[rules.required]"
                      style="font-size: 13px"
                      v-model="solicitud"
                      @input="mostrarSolicitudFolio"
                      label="Solicitudes de acceso"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col class="" cols="6" sm="6">
                    <v-text-field
                      type="datetime-local"
                      dense
                      v-if="events.length == 1"
                      v-model="atraque"
                      style="font-size: 13px"
                      label="Atraque"
                      outlined
                    ></v-text-field>
                    <v-text-field
                      type="date"
                      dense
                      v-else
                      v-model="fecha"
                      style="font-size: 13px"
                      label="Fecha de captura"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="" cols="6" sm="6">
                    <v-text-field
                      type="datetime-local"
                      dense
                      v-model="desatraque"
                      label="Desatraque"
                      style="font-size: 13px"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="" cols="6" sm="6">
                    <v-text-field
                      type="number"
                      v-model="embarque_pax"
                      dense
                      label="Embarque PAX"
                      outlined
                      style="font-size: 13px"
                    ></v-text-field>
                  </v-col>
                  <v-col class="" cols="6" sm="6">
                    <v-text-field
                      type="number"
                      v-model="desembarque_pax"
                      dense
                      label="Desembarque PAX"
                      style="font-size: 13px"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="" cols="6" sm="6">
                    <v-text-field
                      type="number"
                      dense
                      v-model="embarque_mat"
                      label="Embarque Mat"
                      style="font-size: 13px"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="" cols="6" sm="6">
                    <v-text-field
                      type="number"
                      dense
                      style="font-size: 13px"
                      v-model="desembarque_mat"
                      label="Desembarque Mat"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="" cols="6" sm="6">
                    <v-text-field
                      type="number"
                      dense
                      style="font-size: 13px"
                      v-model="movimient_int"
                      label="Movimientos internos"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="" cols="12" sm="12">
                    <v-switch v-model="atraq_desat" label="Amarre/Desamarre"></v-switch>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <v-btn
                      color="secondary"
                      @click="insertarBitacora"
                      dark
                      :loading="loading"
                      block
                      >Registrar acceso</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="7">
              <v-row class="fill-height">
                <v-col>
                  <v-sheet height="64">
                    <v-toolbar flat>
                      <v-btn
                        outlined
                        class="mr-4"
                        color="grey darken-2"
                        @click="setToday"
                      >
                        Hoy
                      </v-btn>
                      <v-btn fab text small color="grey darken-2" @click="prev">
                        <v-icon small> chevron_left </v-icon>
                      </v-btn>
                      <v-btn fab text small color="grey darken-2" @click="next">
                        <v-icon small> chevron_right </v-icon>
                      </v-btn>
                      <v-toolbar-title style="font-size: 14px" v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-menu bottom right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                            <span>{{ typeToLabel[type] }}</span>
                            <v-icon right> arrow_drop_down </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="type = 'day'">
                            <v-list-item-title>Día</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = 'week'">
                            <v-list-item-title>Semana</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = 'month'">
                            <v-list-item-title>Mes</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = '4day'">
                            <v-list-item-title>4 dias</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-toolbar>
                  </v-sheet>
                  <v-sheet height="500">
                    <v-calendar
                      ref="calendar"
                      v-model="focus"
                      color="primary"
                      :events="events"
                      :event-color="getEventColor"
                      :type="type"
                      @click:event="showEvent"
                    ></v-calendar>
                    <v-menu
                      v-model="selectedOpen"
                      :close-on-content-click="false"
                      :activator="selectedElement"
                      offset-x
                    >
                      <v-card color="grey lighten-4" min-width="550px">
                        <v-toolbar :color="selectedEvent.color" dark>
                          <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                          <v-spacer></v-spacer>

                          <!-- <v-btn icon>
                            <v-icon>more_vert</v-icon>
                          </v-btn> -->
                        </v-toolbar>
                        <v-card
                          v-if="selectedEvent.detail && selectedEvent.color == 'blue'"
                          class="custom-card pa-4"
                        >
                          <v-card-title class="custom-title"> Detalles </v-card-title>
                          <v-card-subtitle>
                            <v-list dense>
                              <v-list-item dense class="custom-list-item">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Estadia:
                                    <strong class="custom-value"
                                      >{{ selectedEvent.detail.estadia }} hrs</strong
                                    >
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense class="custom-list-item">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Cuota de arribo:
                                    <strong class="custom-value">{{
                                      selectedEvent.detail.cuota_arribo
                                    }}</strong>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense class="custom-list-item">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Cuota de atraque:
                                    <strong class="custom-value">{{
                                      selectedEvent.detail.cuota_atraque
                                    }}</strong>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense class="custom-list-item">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Cuota de amarre / desamarre:
                                    <strong class="custom-value">{{
                                      selectedEvent.detail.tarifa_amarre_desamarre
                                    }}</strong>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense class="custom-list-item">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Tarifa pasajeros:
                                    <strong class="custom-value">{{
                                      selectedEvent.detail.tarifa_pasajeros
                                    }}</strong>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense class="custom-list-item">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Tarifa muellaje:
                                    <strong class="custom-value">{{
                                      selectedEvent.detail.muellaje
                                    }}</strong>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense class="custom-list-item">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Tarifa movimientos internos:
                                    <strong class="custom-value">{{
                                      selectedEvent.detail.tarifa_mov_intern
                                    }}</strong>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense class="custom-list-item">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Subtotal:
                                    <strong class="custom-value">{{
                                      selectedEvent.detail.subtotal.toFixed(2)
                                    }}</strong>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card-subtitle>
                          <v-card-actions>
                            <v-btn text color="secondary" @click="selectedOpen = false">
                              Salir
                            </v-btn>
                          </v-card-actions>
                        </v-card>

                        <v-card
                          v-if="selectedEvent.detail && selectedEvent.color == 'grey'"
                          class="custom-card"
                        >
                          <v-card-subtitle>
                            <v-list dense>
                              <v-list-item dense class="custom-list-item">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <p style="font-size: 11px; color: gray">
                                      El rango de fecha mostrado, es un tentativo del
                                      tiempo que se encontrara <br />
                                      la embaracación en el muelle de a cuerdo a la
                                      solicitud de la agencia
                                    </p>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense class="custom-list-item">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    ETA:
                                    <strong class="custom-value">{{
                                      selectedEvent.detail.eta
                                    }}</strong>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense class="custom-list-item">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    ETD:
                                    <strong class="custom-value">
                                      {{ selectedEvent.detail.etd }}
                                    </strong>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card-subtitle>
                          <v-card-actions>
                            <v-btn text color="secondary" @click="selectedOpen = false">
                              Salir
                            </v-btn>
                          </v-card-actions>
                        </v-card>

                        <v-card
                          v-if="selectedEvent.detail && selectedEvent.color == 'orange'"
                          class="custom-card"
                        >
                          <v-card-subtitle>
                            <v-list dense>
                              <v-list-item dense class="custom-list-item">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <p style="font-size: 11px; color: gray">
                                      El rango de fecha mostrado, es un tentativo del
                                      tiempo que se encontrara <br />
                                      la embaracación en el muelle de a cuerdo a la
                                      solicitud de la agencia
                                    </p>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense class="custom-list-item">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    ATRAQUE:
                                    <strong class="custom-value">{{
                                      selectedEvent.detail.atraque
                                    }}</strong>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense class="custom-list-item">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    ETD:
                                    <strong class="custom-value">
                                      {{ selectedEvent.detail.etd }}
                                    </strong>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card-subtitle>
                          <v-card-actions>
                            <v-btn text color="secondary" @click="selectedOpen = false">
                              Salir
                            </v-btn>
                          </v-card-actions>
                        </v-card>

                        <v-card
                          v-if="selectedEvent.detail && selectedEvent.color == 'red'"
                          class="custom-card"
                        >
                          <v-card-subtitle>
                            <v-list dense>
                              <v-list-item dense class="custom-list-item">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <p style="font-size: 11px; color: gray">
                                      No se han encontrado registros para el día faltante,
                                      pero existen registros previos. <br />
                                      Favor de ingresar la estadía de la embarcación para
                                      ese día.
                                    </p>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card-subtitle>
                          <v-card-actions>
                            <v-btn text color="secondary" @click="selectedOpen = false">
                              Salir
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                        <v-card
                          v-if="selectedEvent.detail && selectedEvent.color == 'green'"
                          class="custom-card"
                        >
                          <v-card-subtitle>
                            <v-list dense>
                              <v-list-item dense class="custom-list-item">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Atraque:
                                    <strong class="custom-value">{{
                                      selectedEvent.detail.atraque
                                    }}</strong>
                                  </v-list-item-title>
                                  <v-list-item-title>
                                    Desatraque:
                                    <strong class="custom-value">{{
                                      selectedEvent.detail.desatraque
                                    }}</strong>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card-subtitle>
                          <v-card-actions>
                            <v-btn text color="secondary" @click="selectedOpen = false">
                              Salir
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-card>
                    </v-menu>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text>Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout>
      <v-flex>
        <v-row>
          <v-col cols="12">
            <div class="input-filter">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" color="primary" class="mb-2">
                    <v-icon>filter_list</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item dense @click="showFilterFolio = true">
                    <v-list-item-title>
                      <span class="texto">Folio</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item dense @click="showFilterFecha = true">
                    <v-list-item-title>
                      <span class="texto">Fecha</span></v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
              <div class="text-center"></div>
              <div v-if="arr.length == 0" class="filter-label">
                <span>Ningun filtro seleccionado...</span>
              </div>
              <div v-else class="filter-chips">
                <v-chip
                  class="ml-2"
                  v-for="(item, index) in arr"
                  :key="index"
                  close
                  close-icon="mdi-close-circle"
                  @click:close="eliminarFiltro(index)"
                  color="primary"
                  small
                >
                  {{ item.label }}
                </v-chip>
              </div>

              <v-dialog
                v-model="showFilterFecha"
                transition="dialog-bottom-transition"
                max-width="350"
              >
                <v-card>
                  <v-toolbar color="primary" dark>Busqueda por Fecha</v-toolbar>
                  <v-card-text class="mt-10">
                    <v-text-field
                      type="date"
                      outlined
                      dense
                      label="Ingrese la fecha que decea filtrar"
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="filtroLabelPush('fecha')">Aplicar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <v-col cols="12">
            <material-card
              icon="content_paste"
              title="Registros capturados "
              class="px-5 py-8"
            >
              <v-simple-table class="rounded-table mt-3" style="" dense>
                <thead class="rounded-table" style="background-color: rgb(128, 0, 32)">
                  <tr style="border-radius: 15px">
                    <th class="white--text">Folio</th>
                    <th class="white--text">Embarcación</th>
                    <th class="white--text">Agencia</th>
                    <th class="white--text">Atraque</th>
                    <th class="white--text">Desatraque</th>
                    <th class="white--text">Pasajeros</th>
                    <th class="text-right white--text">Opciones</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item, index) in bitacora" :key="index">
                    <td style="font-size: 12px; font-weight: bold">
                      SU-{{ item.folio_solicitud.folio_solicitud }}
                    </td>
                    <td style="font-size: 12px; color: gray; font-weight: bold">
                      <!-- {{ item.folio_solicitud.embarcacion.nombre }} -->
                    </td>
                    <td style="font-size: 12px; color: gray; font-weight: bold">
                      <!-- {{ item.folio_solicitud.agencia.nombre }} -->
                    </td>
                    <td style="font-size: 12px; color: gray; font-weight: bold">
                      {{ item.atraque }}
                    </td>

                    <td style="font-size: 12px; color: gray; font-weight: bold"></td>
                    <td class="text-right">
                      <v-menu bottom left rounded="lg">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>more_horiz</v-icon>
                          </v-btn>
                        </template>

                        <v-list dense>
                          <v-list-item link>
                            <v-list-item-title>Editar</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <!-- <v-icon
                    small
                    v-if="item.estado == 0"
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    edit
                  </v-icon>
                  <v-icon v-if="item.estado == 2" class="mr-2" @click="editItem(item)">
                    edit
                  </v-icon>

                  <v-icon
                    v-if="item.estado"
                    class="mr-2"
                    @click="mostrarComprobante(item)"
                  >
                    text_snippet
                  </v-icon> -->
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </material-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import {
  getFoliosSolicitudes,
  insertBitacoraEmbarcación,
  listAccesoFolio,
  listBitacora,
} from "../../api/endpoints";
import LoadingOverlay from "../components/Loading.vue";
import MaterialCard from "../components/MaterialCard.vue";
export default {
  components: {
    LoadingOverlay,
    MaterialCard,
  },
  data() {
    return {
      showFilterFolio: false,
      loading: true,
      solicitudes: [],

      showFilterFecha: false,
      //  solicitudes: ["Flecha de Oro SU-23", "Flecha de Oro SU-23", "Arrow 1 SU-39"],
      dialogBitacora: false,
      headers: [
        { text: "Folio", value: "folio_solicitud.folio_solicitud" },
        { text: "Embarcación", value: "folio_solicitud.embarcacion.nombre" },
        { text: "Agencia", value: "folio_solicitud.agencia.nombre" },
        { text: "Atraque", value: "atraque" },
        { text: "Desatraque", value: "desatraque" },
        { text: "Amarre/Desamarre", value: "atraque_desatraque" },
        { text: "Movimientos internos", value: "movimiento_interno" },
        { text: "Embarque (Ton)", value: "estado" },
        { text: "Desembarque (Ton)", value: "estado" },
        { text: "Opciones", value: "opciones", align: "right" },
      ],
      arr: [],
      solicitud: "",
      atraque: "",
      fecha: "",
      desatraque: "",
      embarque_pax: 0,
      desembarque_pax: 0,
      bitacora: [],
      embarque_mat: 0,
      desembarque_mat: 0,
      atraq_desat: false,
      movimient_int: 0,
      existAtraque: false,
      //CALENDARIO
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Mes",
        week: "Semana",
        day: "Día",
        "4day": "4 Dias",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      rules: {
        required: (value) => !!value || "Este campo es requerido",
      },
    };
  },
  created() {
    this.obtenerFoliosSolicitud();
    this.listar();
  },
  methods: {
    eliminarFiltro(index) {
      this.arr.splice(index, 1);
    },

    mostrarSolicitudFolio() {
      listAccesoFolio(this.solicitud)
        .then((e) => {
          this.events = e;
          console.log(e);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    listar() {
      listBitacora()
        .then((e) => {
          this.bitacora = e;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    obtenerFoliosSolicitud() {
      this.loading = true;
      getFoliosSolicitudes()
        .then((e) => {
          console.log(e);
          this.solicitudes = e;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    insertarBitacora() {
      this.loading = true;
      let fecha = "";
      if (this.fecha) {
        const dateInput = new Date(this.fecha);
        dateInput.setUTCHours(6);
        fecha = dateInput.toISOString();
      } else {
        fecha = this.atraque;
      }

      const datos = {
        folio_solicitud: this.solicitud,
        atraque: fecha,
        desatraque: new Date(this.desatraque),
        embarque_px: this.embarque_pax,
        desembarque_px: this.desembarque_pax,
        embarque_mat: this.embarque_mat,
        desembarque_mat: this.desembarque_mat,
        atraque_desatraque: this.atraq_desat,
        movimiento_interno: this.movimient_int,
      };
      insertBitacoraEmbarcación(datos)
        .then(() => {
          this.$notify({
            title: "¡Éxito!",
            text: "La operación se completo con éxito",
            type: "success",
          });
          this.atraque = "";
          this.fecha = "";
          this.desatraque = "";
          this.mostrarSolicitudFolio();
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            text:
              "Esto puede deberse a que hay fechas pendientes de caputura o a que estás intentando registrar un atraque en una fecha que ya tiene un atraque registrado.",
            type: "error",
          });
          this.fecha = "";
          // this.atraque = "";
          // this.desatraque = "";
        })
        .finally(() => {
          this.loading = false;
        });
    },

    filtroLabelPush(tipo) {
      if (tipo == "folio") {
        let existe = this.arr.findIndex((e) => e.field == "folio");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "folio_falla",
          value: this.folio_filter,
          label: "Folio : " + this.folio_filter,
          collection: "rf",
        });
      } else if (tipo == "fecha") {
        const fecha = new Date(this.fecha_filter);
        let existe = this.arr.findIndex((e) => e.field == "fecha");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "fechaFalla",
          value: fecha,
          label: "Fecha : " + this.fecha_filter,
          collection: "rf",
        });
      }
      this.showFilterFolio = false;
      this.showFilterFecha = false;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getEventColor(event) {
      return event.color;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },

    // updateRange({ start, end }) {
    //   const events = [];

    //   const min = new Date();
    //   const max = new Date();

    //   events.push({
    //     name: "Arrow 1 - SU-12",
    //     start: min,
    //     end: max,
    //     color: "orange",
    //     detail: "Solicitud de acceso 2 pasajeros ",
    //     //timed: !allDay,
    //   });

    //   this.events = events;
    // },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    showEvent({ nativeEvent, event }) {
      console.log(event);
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");

.texto {
  font-family: "Raleway", sans-serif;
}

.v-data-table >>> .v-data-table-header {
  background-color: rgb(128, 0, 32) !important;
}

.input-filter {
  background-color: rgb(241, 241, 241);
  border-radius: 20px;
  padding: -5px;
  width: 100%;
  height: 40px;
}

.input-filter_seguimiento {
  background-color: rgb(226, 226, 226);
  border-radius: 20px;
  padding: -45px;
  width: 100%;
  height: 25px;
}

.input-filter_title {
  background-color: rgb(29, 29, 29);
  border-radius: 20px;
  padding: -45px;
  color: white;
  width: 100%;
  text-align: center;
  height: 25px;
}

.button-fab {
  bottom: 50px;
  height: 60px;
  position: fixed;
  right: 20px;
  width: 60px;
}

.filter-label {
  color: #b1adad;
  margin-top: -35px;
  margin-left: 40px;
  font-size: 13px;
  font-weight: normal;
}

.filter-chips {
  color: #b1adad;
  padding: -10px;
  margin-top: -41px;
  margin-left: 40px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

.v-input--select .v-label {
  font-size: 2px;
}
</style>
