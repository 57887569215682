<template>
  <div>
    <h2 style="color: gray; text-align: center">
      Resultados de encuestas de satisfacción
    </h2>
    <v-container>
      <v-card v-for="(question, key) in questions" :key="key" class="mb-4">
        <v-card-title>
          <v-icon left>quiz</v-icon>
          {{ question.text }}
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
          <v-list>
            <v-list-item v-for="(response, index) in responses[key]" :key="index">
              <v-list-item-content>
                <v-icon left>mdi-format-quote-close</v-icon>
                <span class="font-italic">"{{ response.respuesta }}"</span>
                <v-list-item-subtitle>
                  <strong>{{ response.usuario.nombre }}</strong> -
                  {{ response.usuario.rol }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-chip small>{{ response.usuario.empresa }}</v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { getAllSurvey } from "../../api/endpoints";

export default {
  data() {
    return {
      questions: {
        satisfaction: {
          text:
            "En términos generales, ¿cómo calificarías tu nivel de satisfacción con SAM?",
        },
        qrUsage: {
          text:
            "¿Has utilizado la funcionalidad de pases de acceso por QR para ingresar al puerto?",
        },
        improvement: {
          text:
            "¿Consideras que SAM ha mejorado el proceso con respecto a la gestión de accesos y autorizaciones que se hacía antes por correo electrónico?",
        },
        easeOfUse: {
          text:
            "¿Qué tan fácil te resulta encontrar y utilizar las funciones principales en SAM?",
        },
        timeSaving: {
          text:
            "¿Consideras que realizar solicitudes o revisar su estatus en SAM te ahorra tiempo en comparación con el método anterior?",
        },
        technicalIssues: {
          text: "¿Con qué frecuencia encuentras problemas técnicos al usar SAM?",
        },
        processImprovement: {
          text:
            "¿Consideras que SAM ha agilizado los trámites de acceso y la organización de la documentación?",
        },
        communicationBenefit: {
          text:
            "¿Crees que SAM beneficia la comunicación entre las diferentes partes involucradas?",
        },
        portEfficiencyComparison: {
          text:
            "¿Cómo compararías la eficiencia y facilidad de los trámites en el puerto de Frontera con SAM frente a otros puertos?",
        },
        improvements: { text: "¿Qué aspectos mejorarías en SAM?" },
        comments: { text: "Cualquier otra sugerencia o comentario adicional:" },
      },
      responses: {},
    };
  },
  created() {
    this.getAllSurvey();
  },
  methods: {
    async getAllSurvey() {
      try {
        const response = await getAllSurvey();
        this.responses = response.data;
      } catch (error) {
        console.error("Error al verificar el estado de la encuesta:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
