<template>
  <div>
    <loading-overlay :overlay="loading"></loading-overlay>
    <v-dialog v-model="showSurvey" max-width="800px">
      <v-card>
        <v-toolbar color="primary" dark>
          <span style="font-size: 20px; font-weight: 500">Encuesta de Satisfacción</span>
        </v-toolbar>
        <v-card-text>
          <v-form v-model="valid" ref="surveyForm">
            <v-container>
              <v-row dense>
                <v-col cols="12" v-for="(question, key) in questions" :key="key">
                  <p class="question">{{ question.text }}</p>
                  <v-radio-group
                    v-if="question.type === 'radio'"
                    v-model="survey[key]"
                    :rules="[(v) => !!v || 'Este campo es obligatorio']"
                  >
                    <v-radio
                      v-for="(option, index) in question.options"
                      :key="index"
                      :label="option"
                      :value="option"
                    ></v-radio>
                  </v-radio-group>
                  <v-textarea
                    v-else
                    v-model="survey[key]"
                    label="Escribe tu respuesta aquí..."
                    outlined
                    :rules="[(v) => !!v || 'Este campo es obligatorio']"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="loading" dark @click="submitSurvey"
            >Enviar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { addFeedback, getFeedbackUser } from "../api/endpoints";
import LoadingOverlay from "../views/components/Loading.vue";

export default {
  components: {
    LoadingOverlay,
  },
  data() {
    return {
      hasCompletedSurvey: false,
      showSurvey: false,
      valid: false,
      loading: false,
      survey: {},
      questions: {
        satisfaction: {
          text:
            "En términos generales, ¿cómo calificarías tu nivel de satisfacción con SAM?",
          type: "radio",
          options: [
            "Muy Insatisfecho",
            "Insatisfecho",
            "Neutral",
            "Satisfecho",
            "Muy satisfecho",
          ],
        },
        qrUsage: {
          text:
            "¿Has utilizado la funcionalidad de pases de acceso por QR para ingresar al puerto?",
          type: "radio",
          options: ["Si", "No"],
        },
        improvement: {
          text:
            "¿Consideras que SAM ha mejorado el proceso con respecto a la gestión de accesos y autorizaciones que se hacía antes por correo electrónico?",
          type: "radio",
          options: [
            "Mucho peor que antes",
            "Algo peor que antes",
            "Igual que antes",
            "Algo mejor que antes",
            "Mucho mejor que antes",
          ],
        },
        easeOfUse: {
          text:
            "¿Qué tan fácil te resulta encontrar y utilizar las funciones principales en SAM?",
          type: "radio",
          options: [
            "Muy difícil",
            "Difícil",
            "Ni fácil ni difícil",
            "Fácil",
            "Muy fácil",
          ],
        },
        timeSaving: {
          text:
            "¿Consideras que realizar solicitudes o revisar su estatus en SAM te ahorra tiempo en comparación con el método anterior?",
          type: "radio",
          options: [
            "Ahorra mucho menos tiempo",
            "Ahorra un poco menos tiempo",
            "No veo diferencia",
            "Ahorra un poco más de tiempo",
            "Ahorra mucho más tiempo",
          ],
        },
        technicalIssues: {
          text: "¿Con qué frecuencia encuentras problemas técnicos al usar SAM?",
          type: "radio",
          options: [
            "Muy frecuentemente",
            "Frecuentemente",
            "Ocasionalmente",
            "Rara vez",
            "Nunca",
          ],
        },
        processImprovement: {
          text:
            "¿Consideras que SAM ha agilizado los trámites de acceso y la organización de la documentación?",
          type: "radio",
          options: [
            "Sí, de manera significativa",
            "Sí, moderadamente",
            "No ha cambiado mucho",
            "Ha dificultado un poco la operación",
            "La ha dificultado mucho",
          ],
        },
        communicationBenefit: {
          text:
            "¿Crees que SAM beneficia la comunicación entre las diferentes partes involucradas?",
          type: "radio",
          options: ["Definitivamente no", "No", "Neutral", "Sí", "Definitivamente sí"],
        },
        portEfficiencyComparison: {
          text:
            "¿Cómo compararías la eficiencia y facilidad de los trámites en el puerto de Frontera con SAM frente a otros puertos?",
          type: "radio",
          options: [
            "Mucho más ineficiente",
            "Un poco más ineficiente",
            "Igual",
            "Un poco más eficiente",
            "Mucho más eficiente",
          ],
        },
        improvements: {
          text: "¿Qué aspectos mejorarías en SAM?",
          type: "text",
        },
        comments: {
          text: "Cualquier otra sugerencia o comentario adicional:",
          type: "text",
        },
      },
    };
  },
  created() {
    this.checkSurveyStatus();
  },
  methods: {
    async submitSurvey() {
      this.loading = true;
      if (this.$refs.surveyForm.validate()) {
        await addFeedback(this.survey)
          .then(() => {
            this.$notify({
              title: "¡Gracias por tu participación!",
              text:
                "Tus respuestas nos ayudarán a seguir optimizando SAM y a mejorar la experiencia de todos los usuarios que operan en el puerto de Frontera. ",
              type: "success",
            });
            this.showSurvey = false;
          })
          .catch((err) => {
            this.$notify({
              title: "Alerta!",
              text: "Por favor, inténtalo de nuevo o verifica los campos obligatorios.",
              type: "warning",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async checkSurveyStatus() {
      this.loading = true;
      try {
        const response = await getFeedbackUser();
        this.hasCompletedSurvey = response.data.hasCompletedSurvey;
        this.hasCompletedSurvey ? (this.showSurvey = false) : (this.showSurvey = true);
      } catch (error) {
        console.error("Error al verificar el estado de la encuesta:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.question {
  font-weight: bold;
  margin-bottom: 8px;
}
</style>
